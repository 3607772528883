import { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext(undefined);

// Export the hook as default
function useUser() {
  const context = useContext(UserContext);  
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }    
  return context;
}

// Export the provider as named export
export function UserProvider({ children }) {
  // Initialize from sessionStorage instead of localStorage
  const [userData, setUserData] = useState(() => {
    const savedUser = sessionStorage.getItem('userData');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  // Update sessionStorage whenever userData changes
  useEffect(() => {
    if (userData) {
      sessionStorage.setItem('userData', JSON.stringify(userData));
    } else {
      sessionStorage.removeItem('userData');
    }
  }, [userData]);

  const value = {
    userData,
    setUserData,
    logout: () => {
      setUserData(null);
      sessionStorage.removeItem('userData');
    }
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

export default useUser; 