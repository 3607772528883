import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Button from './base/Button';
import useUser from '../context/UserContext';

function LoginPage({ onLoginSuccess }) {
//const LoginPage = ({ onLoginSuccess }) => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const { userData, setUserData } = useUser();
  const { setUserData } = useUser();

  async function handleSubmit(e) {
  // const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch('https://n3.bite-size.tech/webhook/sleep-bot-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, password }),
      });

      const data = await response.json();
      console.log('Login Response Data:', data);
      
      if (response.ok && data.result === true) {
        setUserData(data);
        onLoginSuccess();
      } else {
        setError(data.message || 'Login failed');
      }
    } catch (err) {
      console.error('Login Error:', err);
      setError('Network error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container" style={{ width: '300px' }}>
      <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Login</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <input
            type="text"
            value={id}
            onChange={(e) => setId(e.target.value)}
            placeholder="ID"
            style={{
              width: '100%',
              padding: '8px',
              paddingRight: '35px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              boxSizing: 'border-box',
            }}
          />
        </div>
        <div style={{ marginBottom: '15px', position: 'relative' }}>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            style={{
              width: '100%',
              padding: '8px',
              paddingRight: '35px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              boxSizing: 'border-box',
            }}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: 'absolute',
              right: '8px',
              top: '50%',
              transform: 'translateY(-50%)',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              padding: '4px',
              color: '#666',
            }}
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>
        {error && (
          <div style={{ color: '#f03e3e', marginBottom: '15px', textAlign: 'center' }}>
            {error}
          </div>
        )}
        <Button
          label={isLoading ? 'Logging in...' : 'Login'}
          onClick={handleSubmit}
          isLoading={isLoading}
          style={{ width: '100%' }}
        />
      </form>
    </div>
  );
};

export default LoginPage; 