import { useEffect, useState } from "react";

import ActiveCallDetail from "./components/ActiveCallDetail";
import Button from "./components/base/Button";
import Vapi from "@vapi-ai/web";
import { isPublicKeyMissingError } from "./utils";
import LoginPage from "./components/LoginPage";
import useUser from "./context/UserContext";
// Put your Vapi Public Key below.
const vapi = new Vapi("d9bd7b94-4587-4de5-bea1-5fb3970aa446");

const App = () => {
  const { userData, setUserData } = useUser();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const userData = sessionStorage.getItem('userData');
    return !!userData; // Convert to boolean
  });
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);

  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);

  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } = usePublicKeyInvalid();

  // hook into Vapi events
  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("error", (error) => {
      console.error(error);

      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    // we only want this to fire on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add useEffect to handle session storage changes
  useEffect(() => {
    const handleStorageChange = () => {
      const userData = sessionStorage.getItem('userData');
      setIsAuthenticated(!!userData);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // call start handler
  const startCallInline = async () => {
    try {

      const response = await fetch('https://n3.bite-size.tech/webhook/sleep-bot-decrement', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: userData.id }),
      });

      const data = await response.json();
      console.log('Decrement Response Data:', data);
      
      if (response.ok && data.result === true) {
        setUserData(data);
      } else {
        
      }
    } catch (err) {
      
    } finally {

    }

    setConnecting(true);
    // vapi.start(assistantOptions);
    // https://dashboard.vapi.ai/assistants/7d36200c-9879-4adb-9b56-04ad6577f721
    // assistantId

    // vapi.start('5caa306b-b313-4e50-9b29-49a39c2aa8fe'); // sleep bot v1
    // vapi.start('2bee0ef0-f3ba-4748-8b4a-248a401531e5'); // sleep bot v2
    vapi.start('13364d4d-f913-44f7-8070-9f0074047f5c'); // sleep bot v3
    
  };
  const endCall = () => {
    vapi.stop();
  };

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  return (
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: "linear-gradient(to right, #0e0e2c, #342d4b)", /* Example gradient */
          color: "white",
        }}
      >
        {!isAuthenticated ? (
          <LoginPage onLoginSuccess={handleLoginSuccess} />
        ) : (
          <div className="main-container">
            <header>
              <h1>Voice Demo</h1>
              <p>Attempts left: {userData.count_left}</p>
              <br/>
            </header>
              {!connected ? (
                <div>
                <p>Click below to start</p>
                <br/>
                <Button
                    label="Sound Sleep Therapy Clinic"
                    onClick={startCallInline}
                    isLoading={connecting}
                  />
                </div>
                ) : (
                  <div>
                    <p>If the conversation pauses, please say "continue".</p>
                    <br/>
                    <ActiveCallDetail
                      assistantIsSpeaking={assistantIsSpeaking}
                      volumeLevel={volumeLevel}
                      onEndCallClick={endCall}
                    />
                  </div>
                  
                )}
          </div>
        )}
        {showPublicKeyInvalidMessage ? <PleaseSetYourPublicKeyMessage /> : null}
      </div>
  );
};

const usePublicKeyInvalid = () => {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState(false);

  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
};

const PleaseSetYourPublicKeyMessage = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "25px",
        left: "25px",
        padding: "10px",
        color: "#fff",
        backgroundColor: "#f03e3e",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Is your Vapi Public Key missing? (recheck your code)
    </div>
  );
};

export default App;